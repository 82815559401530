import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import "./App.css"
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Index from "./pages";
// import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

import Meeting from "./pages/meeting";

function ScrollToTop() {
 
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);

    

  }, [pathname]);

  return null;
}


function App() {


  //   const myMeeting = async (element) => {
  //     const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
  //         parseInt("1995430512"),
  //         "376e295b543ff6157b5860d87458b953",
  //         "0yFKigd4",//meeting id
  //        user,
  //         user,
  //         // user?.uid ? user.uid : generateMeetingID(),
  //         // user?.displayName ? user.displayName : generateMeetingID()
  //     );
  //     const zp = ZegoUIKitPrebuilt.create(kitToken);

  //     zp?.joinRoom({
  //         container: element,
  //         // showPreJoinView: true,
  //         // showScreenSharingButton: true,
  //         // showRemoveUserButton: true,
  //         // showRoomTimer: true,
  //         // showScreenSharingButton: false,
  //         // maxUsers: 50,
  //         showMyMicrophoneToggleButton:false,
  //         sharedLinks: [
  //             {
  //                 name: "Wabinar YouGutMap",
  //                 url: "https://webinar.yourgutmap.co.uk",
  //             },
  //         ],
  //         scenario: {
  //             mode: "LiveStreaming",
  //             config: {
  //                 role: "Audience",
  //             }
  //         },
  //     });


  //     //   {
  //     //     turnOnMicrophoneWhenJoining: true,
  //     //     turnOnCameraWhenJoining: true,
  //     //     showMyCameraToggleButton: true,
  //     //     showMyMicrophoneToggleButton: true,
  //     //     showAudioVideoSettingsButton: true,
  //     //     showScreenSharingButton: true,
  //     //     showTextChat: true,
  //     //     showUserList: true,
  //     //     maxUsers: 50,
  //     //     layout: "Auto",
  //     //     showLayoutButton: true,
  //     //     scenario: {
  //     //       mode: "VideoConference",
  //     //       config: {
  //     //         role: "Host",
  //     //     },
  //     //   },
  //     // }

  //     // ....
  //     // zp.joinRoom({
  //     //   // ...,
  //     //   onJoinRoom: () => {
  //     //     // Add your custom logic
  //     //   },
  //     //   onLeaveRoom: () => {
  //     //     // Add your custom logic
  //     //   }
  //     //   // ...
  //     // });
  // };








  return (<>
  <div className="main">
    <BrowserRouter>
      <ScrollToTop />


      <Routes>

        <Route path='/' element={<Index />} />
        <Route path='/:id' element={<Meeting />} />
      </Routes>
    </BrowserRouter>
    </div>
  </>)
}


export default App;