import React from 'react'
import "./css/navbar.css"

function navbar() {
  return (
    <div className='navbar'>
      <div className="logo">
        <img src="https://yourgutmap.co.uk/wp-content/uploads/2023/03/ygm-logo.png" alt="" />
        <img src="" alt="" />
      </div>
      <div className="bar">
        <a href="https://yourgutmap.co.uk/">Home</a>
        <div className='list1'>
          <a href="https://yourgutmap.co.uk/microbiome/">YourGutmap&reg; Tests</a>
         
        </div>
        <div className="aboutlist">
          <a href="https://yourgutmap.co.uk/about/">About</a>
          

        </div>
        <a href="https://yourgutmap.co.uk/practitioners/">Practitioners</a>
        <a href="https://yourgutmap.co.uk/vitamin-angels/">Vitamin Angels</a>
        <a href="">Academy</a>
        <button>Shop</button>
      </div>
    </div>
  )
}

export default navbar
